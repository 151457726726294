import { AuthProvider } from "~/utils/context/auth";
import React, { ReactElement } from "react";
import { CreatorProvider } from "~/utils/context/creator";
import { EvervaultProvider } from "@evervault/react";
import { AgencyProvider } from "~/utils/context/agency";

interface CreatorAppLayoutProps {
	children: ReactElement;
}

export const CreatorAppLayout: React.FC<CreatorAppLayoutProps> = ({
	children,
}) => {
	return (
		<AuthProvider>
			<EvervaultProvider
				teamId={process.env.NEXT_PUBLIC_EVERVAULT_TEAM_ID ?? ""}
				appId={process.env.NEXT_PUBLIC_EVERVAULT_APP_ID ?? ""}
			>
				<AgencyProvider>
					<CreatorProvider>{children}</CreatorProvider>
				</AgencyProvider>
			</EvervaultProvider>
		</AuthProvider>
	);
};
